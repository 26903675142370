import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { API_ROOT } from "../../defines";
import Animated from "../../components/Animated";
import { Link } from "react-router-dom";
import { FaClock, FaEye } from "react-icons/fa";

const ArticleCard = (article) => {
	const { t, i18n } = useTranslation();

	const thumb = API_ROOT + "/" + objectPath.get(article, "thumb.0.id");
	const title = objectPath.get(article, `title.${i18n.language}`);

	const category = objectPath.get(article, `category.value`);

	const tags = objectPath.get(article, "tags");

	return (
		<Animated animation="animate-fade bg-white rounded-lg overflow-hidden shadow-md flex">
			<Link
				to={`/${category}/articles/${article.id}`}
				className="relative flex flex-col flex-1 justify-between"
			>
				<div className="absolute top-4 right-4">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src={`/icons/${category}.svg`}
						className={`shrink-0 w-10 h-10 ${category} active p-1`}
					/>
				</div>
				<img
					alt={title}
					src={thumb}
					className="h-[300px] w-full object-cover"
					crossOrigin="annonymous"
				/>
				<div className="p-5 flex flex-col justify-between bg-white flex-1">
					<div className="font-bold">{title}</div>
					{Array.isArray(tags) && (
						<div className="text-xs">
							{tags.map(({ value }) => `#${t(`tags.${value}`)}`).join(" ")}
						</div>
					)}
					<div className="mt-5 flex gap-5 text-dark-70 justify-end">
						<div className="flex gap-2 items-center">
							<FaClock className="text-blue-100"></FaClock> {article._published}
						</div>
						<div className="flex gap-2 items-center">
							<FaEye className="text-blue-100"></FaEye> {article.views}{" "}
							{t("views")}
						</div>
					</div>
				</div>
			</Link>
		</Animated>
	);
};

export default ArticleCard;
