import { Link } from "react-router-dom";
import Project from "./Project";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

const GreenGate = ({ more }) => {
	const { t } = useTranslation();

	return (
		<Project
			color="green"
			title={t("greengate.title")}
			left={
				<div className="leading-relaxed greengate">
					<Trans t={t}>greengate.desc</Trans>
					<br />
					<br />
					<Trans t={t}>greengate.progress</Trans>
					<br />
					<Trans t={t}>greengate.src</Trans>
					<br />
					<Trans t={t}>greengate.amount</Trans>
					<br />
					<Trans t={t}>greengate.ub</Trans>
					<br />
					<Trans t={t}>greengate.period</Trans>
					{/* <ol className="m-5 list-decimal">
						<li>{t("greengate.gates.0")}</li>
						<li>{t("greengate.gates.1")}</li>
						<li>{t("greengate.gates.2")}</li>
						<li>{t("greengate.gates.3")}</li>
					</ol>
					<Trans t={t}>greengate.outro</Trans> */}
					{more && (
						<div className="flex justify-end mt-5">
							<Link to="/greengate" className="button green">
								{t("more")}
								<FaChevronRight />
							</Link>
						</div>
					)}
				</div>
			}
			right={
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/projects/greengate-min.png"
					className="w-full max-h-96 object-cover"
				/>
			}
		></Project>
	);
};

export default GreenGate;
