import "react-toggle/style.css";
import ReactToggle from "react-toggle";

const Switch = ({ value, onChange, ...props }) => {
	const toggleProps = {
		checked: value,
		onChange: (e) => {
			onChange && onChange(e.target.checked);
		},
		...props,
		className: undefined,
	};

	return <ReactToggle {...toggleProps} />;
};

export default Switch;
