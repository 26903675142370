import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const RichText = ({
	value,
	onChange,
	toolbar = {
		options: ["inline", "list", "textAlign", "embedded"],
	},
	disabled: readOnly,
	...props
}) => {
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	const onBlur = () => {
		const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
		onChange && onChange(html);
	};

	useEffect(() => {
		if (value) {
			const block = htmlToDraft(value);
			if (block) {
				const state = EditorState.createWithContent(
					ContentState.createFromBlockArray(block.contentBlocks)
				);
				setEditorState(state);
			}
		}
	}, [value]);

	const editorProps = {
		editorState,
		onEditorStateChange: setEditorState,
		toolbar,
		readOnly,
		...props,
		onBlur,
	};

	return <Editor {...editorProps} />;
};

export default RichText;
