import "react-datepicker/dist/react-datepicker.css";
import ReactDatePicker from "react-datepicker";

const DateTimePicker = ({
	value: selected,
	timeFormat = "HH:mm",
	dateFormat = "yyyy-MM-dd",
	time = true,
	...props
}) => {
	const pickerProps = {
		selected,
		showTimeSelect: time,
		dateFormat: time ? `${dateFormat} ${timeFormat}` : dateFormat,
		timeFormat,
		...props,
	};

	return <ReactDatePicker {...pickerProps}></ReactDatePicker>;
};

export default DateTimePicker;
