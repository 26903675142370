import { useTranslation } from "react-i18next";
import Animated from "../components/Animated";
import { Element, Link as ScrollLink } from "react-scroll";
import Metro from "../components/Metro";
import Brt from "../components/Brt";
import CableCar from "../components/CableCar";
import GreenGate from "../components/GreenGate";
import { Title } from "../components/Project";

const Home = () => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="fixed right-0 top-1/2 z-30 flex flex-col items-end text-white">
				<ScrollLink
					activeClass="active-scroll"
					className="select-none flex gap-2 overflow-hidden items-center cursor-pointer p-2 bg-red-100 w-10 hover:w-32 transition-all"
					smooth={true}
					spy={true}
					offset={-64}
					to="metro"
				>
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/icons/metro.svg"
						className="shrink-0 w-8 h-8"
					/>
					<div className="whitespace-nowrap text-xs">UB METRO</div>
				</ScrollLink>
				<ScrollLink
					activeClass="active-scroll"
					className="select-none flex gap-2 overflow-hidden items-center cursor-pointer p-2 bg-yellow-100 w-10 hover:w-32 transition-all"
					smooth={true}
					spy={true}
					offset={-64}
					to="brt"
				>
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/icons/brt.svg"
						className="shrink-0 w-8 h-8"
					/>
					<div className="whitespace-nowrap text-xs">UB BRT</div>
				</ScrollLink>
				<ScrollLink
					activeClass="active-scroll"
					className="select-none flex gap-2 overflow-hidden items-center cursor-pointer p-2 bg-blue-100 w-10 hover:w-32 transition-all"
					smooth={true}
					spy={true}
					offset={-64}
					to="cablecar"
				>
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/icons/cablecar.svg"
						className="shrink-0 w-8 h-8"
					/>
					<div className="whitespace-nowrap text-xs">UB CABLECAR</div>
				</ScrollLink>
				<ScrollLink
					activeClass="active-scroll"
					className="select-none flex gap-2 overflow-hidden items-center cursor-pointer p-2 bg-green-100 w-10 hover:w-32 transition-all"
					smooth={true}
					spy={true}
					offset={-64}
					to="green-gate"
				>
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/icons/greengate.svg"
						className="shrink-0 w-8 h-8"
					/>
					<div className="whitespace-nowrap text-xs">UB GREENGATE</div>
				</ScrollLink>
			</div>
			<div className="relative overflow-hidden">
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="ub_city.jpg"
					className="w-full max-h-[600px] object-cover animate-fade"
				/>
				<div className="absolute top-0 left-0 bottom-0 right-0 w-full flex items-center justify-center px-5">
					<Animated>
						<div className="text-white text-xl lg:text-4xl uppercase w-full lg:w-1/2 mx-auto text-center">
							{t("ipiu-full")}
						</div>
					</Animated>
				</div>
			</div>
			<Animated>
				<div className="container mx-auto p-2 py-20">
					<Title title={t("what-we-do")} color={"blue"}></Title>
					<div className="text-center text-xl text-dark-70">{t("we-do")}</div>
				</div>
			</Animated>
			<Element name="metro">
				<div className="container mx-auto p-2 py-10">
					<Metro more={true}></Metro>
				</div>
			</Element>
			<Element name="brt">
				<div className="bg-gray-100">
					<div className="container mx-auto p-2 py-10">
						<Brt more={true}></Brt>
					</div>
				</div>
			</Element>
			<Element name="cablecar">
				<div className="container mx-auto p-2 py-10">
					<CableCar more={true}></CableCar>
				</div>
			</Element>
			<Element name="green-gate">
				<div className="bg-gray-100">
					<div className="container mx-auto p-2 py-10">
						<GreenGate more={true}></GreenGate>
					</div>
				</div>
			</Element>

			<Animated>
				<div className="container mx-auto p-2 py-20">
					<Title title={t("target")} color={"blue"}></Title>
					<div className="text-center text-xl text-dark-70">
						{t("target_desc")}
					</div>
				</div>
			</Animated>
		</div>
	);
};

export default Home;
