import { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

const AutoPlayVideo = ({
	className = "h-96 w-auto mx-auto rounded-lg",
	src,
	...props
}) => {
	const playerRef = useRef();
	const { ref, inView } = useInView({
		threshold: 0.2,
		...props,
	});

	useEffect(() => {
		if (inView) {
			try {
				playerRef.current?.play().catch(() => {});
			} catch {}
		} else {
			playerRef.current?.pause();
		}
	}, [inView]);

	return (
		<div ref={ref}>
			<video
				ref={playerRef}
				controls={true}
				autoPlay={true}
				src={src}
				className={className}
			></video>
		</div>
	);
};

export default AutoPlayVideo;
