import Swal from "sweetalert2";

export const ShowWarning = (text, title = "Анхааруулга", config) => {
	return Swal.fire({
		icon: "warning",
		title,
		text,
		confirmButtonText: "Хаах",
		timer: 5000,
		timerProgressBar: true,
		...config,
	});
};

export const ShowConfirm = (text, title = "Баталгаажуулах", config) => {
	return Swal.fire({
		icon: "info",
		title,
		text,
		showCancelButton: true,
		cancelButtonText: "Цуцлах",
		confirmButtonText: "Тийм",
		...config,
	});
};
