import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import sha256 from "crypto-js/sha256";

import { mainApi } from "../../providers/api";
import FormControl from "../../inputs/FormControl";
import { ShowConfirm, ShowWarning } from "../../providers/alert";
import { FaEdit, FaTimes, FaTrash } from "react-icons/fa";

const UserForm = ({ profile_id }) => {
	const navigate = useNavigate();
	const { user_id } = useParams();

	const id = user_id || profile_id;

	const [loading, setLoading] = useState(id);
	const [readonly, setReadonly] = useState(id);

	const [initialValues, setInitialValues] = useState({
		username: "",
		phone: "",
		name: "",
		position: "",
		active: true,
		signature: [],
	});

	useEffect(() => {
		if (id && readonly) fetchItem(id);
	}, [id, readonly]);

	const fetchItem = async (id) => {
		try {
			setLoading(true);

			const { data } = await mainApi(`user/${id}`);

			setInitialValues(data);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const deleteItem = async () => {
		try {
			setLoading(true);

			await mainApi({
				method: "DELETE",
				url: `user/${id}`,
			});

			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const saveItem = async (values) => {
		try {
			setLoading(true);

			await mainApi({
				method: id ? "PUT" : "POST",
				url: id ? `/user/${id}` : `/user`,
				data: values,
			});

			if (profile_id) {
				return ShowWarning("Мэдээлэл солигдлоо", "Амжилттай");
			}
			navigate(-1);
		} catch (error) {
			ShowWarning(error);
		} finally {
			setLoading(false);
		}
	};

	const changePassword = async (new_password) => {
		const password = sha256(new_password).toString();
		await saveItem({ password });
	};

	return (
		<Formik
			initialValues={initialValues}
			enableReinitialize={true}
			validationSchema={Yup.object({
				username: Yup.string().required("Нэвтрэх нэр оруулна уу"),
			})}
			onSubmit={(values, { setSubmitting }) => {
				saveItem(values);
				setSubmitting();
			}}
		>
			{({ values }) => {
				return (
					<div className="p-5 border shadow-md flex flex-col gap-5 w-1/2 bg-white">
						<h1 className="bordered flex justify-between items-center">
							{id ? "Хэрэглэгчийн мэдээлэл" : "Хэрэглэгч шинээр бүртгэх"}
							{id && (
								<div className="flex gap-2">
									<button
										type="button"
										className="red"
										onClick={async () => {
											const confirm = await ShowConfirm(
												"Энэ мэдээллийг устгахдаа итгэлтэй байна уу"
											);
											if (confirm.isConfirmed) deleteItem();
										}}
										disabled={loading}
									>
										<FaTrash></FaTrash>
									</button>
									<button
										disabled={loading}
										type="button"
										onClick={() => {
											setReadonly(!readonly);
											if (!readonly) setInitialValues();
										}}
									>
										{readonly ? (
											<>
												<FaEdit></FaEdit>
											</>
										) : (
											<>
												<FaTimes></FaTimes>
											</>
										)}
									</button>
								</div>
							)}
						</h1>
						<Form className="gap-5 flex flex-col">
							<Field
								component={FormControl}
								label="Идэвхтэй эсэх"
								name="active"
								fetch={loading}
								readonly={readonly}
								type="switch"
							/>
							<Field
								component={FormControl}
								label="Нэвтрэх нэр"
								name="username"
								fetch={loading}
								readonly={readonly}
								type="text"
							/>
							<Field
								component={FormControl}
								label="Утасны дугаар"
								name="phone"
								fetch={loading}
								readonly={readonly}
								type="text"
							/>
							<Field
								component={FormControl}
								label="Нэр"
								name="name"
								fetch={loading}
								readonly={readonly}
								type="text"
							/>
							<Field
								component={FormControl}
								label="Албан тушаал"
								name="position"
								fetch={loading}
								readonly={readonly}
								type="text"
							/>
							<Field
								component={FormControl}
								name={id ? "Хадгалах" : "Шинээр бүртгэх"}
								fetch={loading}
								readonly={readonly}
								type="submit"
								containerClass="w-full self-end primary"
							/>
							{readonly && profile_id && (
								<Field
									component={FormControl}
									label="Нууц үг солих"
									placeholder="Шинэ нууц үг"
									name="new_password"
									fetch={loading}
									type="password"
								/>
							)}
							{readonly && id && (
								<Field
									component={FormControl}
									name={
										profile_id
											? "Нууц үг шинэчлэх"
											: "Нууц үг анхныхаар сэргээх"
									}
									fetch={loading}
									onClick={() => {
										changePassword(values.new_password || values.phone);
									}}
									type="button"
									containerClass="w-full self-end"
								/>
							)}
						</Form>
					</div>
				);
			}}
		</Formik>
	);
};

export default UserForm;
