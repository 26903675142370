import { useInView } from "react-intersection-observer";

const Animated = ({ children, animation = "animate-fade-up", ...props }) => {
	const { ref, inView } = useInView({
		threshold: 0.2,
		...props,
	});

	return (
		<div
			ref={ref}
			className={`${
				inView ? animation : "opacity-0"
			} animate-duration-[500ms] animate-delay-[300ms]`}
		>
			{children}
		</div>
	);
};

export default Animated;
