import { Link } from "react-router-dom";
import Project from "./Project";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

const Brt = ({ more }) => {
	const { t } = useTranslation();

	return (
		<Project
			color="yellow"
			title={t("brt.title")}
			left={
				<div className="leading-relaxed brt">
					<Trans t={t}>brt.desc</Trans>
					<br />
					<br />
					<Trans t={t}>brt.progress</Trans>
					<br />
					<Trans t={t}>brt.src</Trans>
					<br />
					<Trans t={t}>brt.amount</Trans>
					<br />
					<Trans t={t}>brt.period</Trans>
					{more && (
						<div className="flex justify-end mt-5">
							<Link to="/brt" className="button yellow">
								{t("more")}
								<FaChevronRight />
							</Link>
						</div>
					)}
				</div>
			}
			right={
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/projects/brt-min.png"
					className="w-full max-h-96 object-cover"
				/>
			}
		></Project>
	);
};

export default Brt;
