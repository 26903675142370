import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
	FaChevronLeft,
	FaChevronRight,
	FaPlus,
	FaRegFile,
	FaRegFileAudio,
	FaRegFileExcel,
	FaRegFilePdf,
	FaRegFilePowerpoint,
	FaRegFileVideo,
	FaRegFileWord,
	FaTrash,
} from "react-icons/fa";

import Loader from "./Loader";

const ACCEPT = {
	pdf: { "application/pdf": [] },
	image: { "image/*": [] },
	video: { "video/*": [] },
	audio: { "audio/*": [] },
	excel: {
		"text/csv": [],
		"application/vnd.ms-excel": [],
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
	},
};

const ActionButton = ({ disabled, onClick, children, bg = "bg-black" }) => {
	return (
		<div
			onClick={() => !disabled && onClick()}
			className={`h-5 w-5 ${bg} ${
				disabled
					? "bg-opacity-10"
					: "bg-opacity-60 cursor-pointer hover:opacity-70"
			}  text-white text-xs flex items-center justify-center select-none`}
		>
			{children}
		</div>
	);
};

export const renderFile = (file, axio, height = 100) => {
	const { type, id, url, name } = file;
	const src = url || axio.defaults.baseURL + "/" + id;

	let preview = <FaRegFile className="text-3xl"></FaRegFile>;

	if (type === "ppt")
		preview = <FaRegFilePowerpoint className="text-3xl"></FaRegFilePowerpoint>;
	if (type === "word")
		preview = <FaRegFileWord className="text-3xl"></FaRegFileWord>;
	if (type === "excel")
		preview = <FaRegFileExcel className="text-3xl"></FaRegFileExcel>;
	if (type === "pdf")
		preview = <FaRegFilePdf className="text-3xl"></FaRegFilePdf>;
	if (type === "audio")
		preview = <FaRegFileAudio className="text-3xl"></FaRegFileAudio>;
	if (type === "video")
		preview = <FaRegFileVideo className="text-3xl"></FaRegFileVideo>;

	if (type === "image") {
		preview = (
			<img
				src={src}
				crossOrigin="anonymous"
				alt={name}
				className="object-cover object-center"
				style={{ height }}
			/>
		);
	}

	return (
		<a
			href={src}
			target="_blank"
			rel="noreferrer"
			className="self-center place-self-center mx-auto cursor-pointer hover:opacity-60"
			download={name}
		>
			{preview}
		</a>
	);
};

const FileUpload = ({
	size = 100,
	limit = 1,
	count = 5,
	disabled,
	accept = ["image", "pdf", "video", "audio", "excel"],
	onError,
	root = "files",
	countError = (c) => `Хамгийн ихдээ ${c} файл оруулна`,
	limitError = (l) => `Файлын хэмжээ ${l}MB хэтэрч болохгүй`,
	axio,
	submit,
	value,
	onChange,
	showName = "truncate",
}) => {
	const maxSize = limit * 1024 * 1024;

	const [files, setFiles] = useState([]);
	const [deleteds, setDeleteds] = useState([]);

	const [progress, setProgress] = useState(100);
	const [loading, setLoading] = useState(false);

	const onDrop = async (selectedFiles) => {
		if (!disabled) {
			try {
				onError();
				if (count < files.length + selectedFiles.length) {
					throw new Error(countError(count));
				}

				let data = new FormData();
				selectedFiles.forEach((file) => {
					if (file.size > maxSize) throw new Error(limitError(limit));

					data.append("upload", file);
				});
				setLoading(true);
				setProgress(0);

				const response = await axio({
					method: "POST",
					url: `/file/fs/${root}`,
					data,
					onUploadProgress: (event) => {
						setProgress(Math.round((event.loaded * 100) / event.total));
					},
				});

				const tmp = [...files, ...response.data];

				setFiles(tmp);
				onChange && onChange(tmp);
			} catch (error) {
				onError && onError(error.message);
			} finally {
				setLoading(false);
				setProgress(100);
			}
		}
	};

	useEffect(() => {
		const deleteFiles = async () => {
			try {
				await axio({
					method: "DELETE",
					url: `/file/fs/`,
					data: { deleteds },
				});
				setDeleteds([]);
			} catch (error) {
				console.warn(error);
			}
		};

		if (submit && deleteds.length > 0) {
			deleteFiles();
		}
		// eslint-disable-next-line
	}, [submit]);

	useEffect(() => {
		setFiles(value);
	}, [value]);

	const deleteItem = (id) => {
		const tmp = files.filter((file) => file.id !== id);

		setDeleteds([...deleteds, id]);
		setFiles(tmp);
		onChange && onChange(tmp);
	};

	const swapItem = (index, count) => {
		const tmp = [...files];
		[tmp[index], tmp[index + count]] = [tmp[index + count], tmp[index]];

		setFiles(tmp);
		onChange && onChange(tmp);
	};

	const a = accept.reduce((prev, cur) => {
		prev = { ...prev, ...ACCEPT[cur] };

		return prev;
	}, {});

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		disabled,
		accept: a,
	});

	return (
		<div className="w-full relative">
			{progress < 100 && (
				<div className="w-full h-2 bg-gray-100 relative">
					<div
						className="h-2 left-0 bg-gray-400 absolute"
						style={{ width: `${progress}%` }}
					></div>
				</div>
			)}
			<div className="flex flex-wrap gap-5">
				{Array.isArray(files) &&
					files.map((file, index) => (
						<div
							key={index}
							style={{ height: size, width: size }}
							className="relative shadow-md overflow-hidden flex"
						>
							<div className="absolute top-0 flex justify-between w-full z-10">
								<div className="flex">
									{files.length > 1 && (
										<ActionButton
											disabled={index === 0}
											onClick={() => {
												swapItem(index, -1);
											}}
										>
											<FaChevronLeft></FaChevronLeft>
										</ActionButton>
									)}
									{files.length > 1 && (
										<ActionButton
											disabled={index === files.length - 1}
											onClick={() => {
												swapItem(index, 1);
											}}
										>
											<FaChevronRight></FaChevronRight>
										</ActionButton>
									)}
								</div>
								<ActionButton
									bg={"bg-red-700"}
									onClick={() => {
										deleteItem(file.id);
									}}
								>
									<FaTrash></FaTrash>
								</ActionButton>
							</div>
							{showName && (
								<div
									style={{ fontSize: 10 }}
									className={`absolute bottom-0 flex text-xs z-0 right-0 text-right px-1 leading-none ${showName}`}
								>
									{file.name}
								</div>
							)}
							{renderFile(file, axio)}
						</div>
					))}
				{files.length < count && !disabled && (
					<div
						{...getRootProps()}
						style={{ height: size, width: size }}
						className="bg-gray-200 cursor-pointer hover:opacity-70 flex items-center justify-center text-white text-xs input select-none relative shadow-md"
					>
						<input {...getInputProps()} />
						<div className="text-3xl">{!loading && <FaPlus></FaPlus>}</div>
						{loading && <Loader></Loader>}
					</div>
				)}
			</div>
		</div>
	);
};

export default FileUpload;
