import objectPath from "object-path";
import { useTranslation } from "react-i18next";
import { API_ROOT } from "../../defines";
import Animated from "../../components/Animated";
import { FaClock, FaRegFilePdf } from "react-icons/fa";

const TransparencyCard = (article) => {
	const { t, i18n } = useTranslation();

	const id = objectPath.get(article, "attachment.0.id");
	const title = objectPath.get(article, `title.${i18n.language}`);
	const category = t(
		`transparency_cat.${objectPath.get(article, `category.value`)}`
	);

	if (!id) return null;

	const attachment = API_ROOT + "/" + id;

	return (
		<Animated animation="animate-fade bg-white rounded-lg overflow-hidden shadow-md flex">
			<div className="relative flex flex-col flex-1 justify-between">
				<a
					className="h-[300px] w-full flex items-center justify-center text-5xl"
					href={attachment}
					target="_blank"
				>
					<FaRegFilePdf></FaRegFilePdf>
				</a>
				<div className="p-5 flex flex-col justify-between bg-white flex-1">
					<div className="text-sm">#{category}</div>
					<div className="font-bold uppercase">{title}</div>
					<div className="mt-5 flex gap-5 text-dark-70 justify-end">
						<div className="flex gap-2 items-center">
							<FaClock className="text-blue-100"></FaClock> {article._published}
						</div>
					</div>
				</div>
			</div>
		</Animated>
	);
};

export default TransparencyCard;
