import { NavLink, Outlet } from "react-router-dom";
import { VERSION } from "../defines";
import {
	FaFilePdf,
	FaNewspaper,
	FaSignOutAlt,
	FaUserCog,
	FaUsers,
} from "react-icons/fa";
import { useApp } from "../providers/app";

const MENU = [
	{
		path: "/app/article",
		label: "Мэдээ мэдээлэл",
		icon: <FaNewspaper />,
	},
	{
		path: "/app/transparency",
		label: "Ил тод байдал",
		icon: <FaFilePdf />,
	},
	{
		path: "/app/user",
		label: "Системийн хэрэглэгчид",
		icon: <FaUsers />,
	},
	{
		path: "/app/profile",
		label: "Миний бүртгэл",
		icon: <FaUserCog />,
	},
	{
		path: "/auth/logout",
		label: "Системээс гарах",
		icon: <FaSignOutAlt />,
	},
];

const AppLayout = () => {
	const { user } = useApp();

	return (
		<div className="flex h-screen w-full">
			<div className="w-64 bg-white shadow-md z-10">
				<div className="flex flex-col items-center justify-center my-10">
					<img src="/icon.png" className="h-16" alt="IPIU" />
					<div className="text-dark-70 mt-5">Сайн байна уу?</div>
					<div className="text-blue-100 text-lg">{user?.name}</div>
					<div className="text-dark-70 text-xs">v{VERSION}</div>
				</div>
				<div className="text-sm text-dark-70">
					{MENU.map(({ path, label, icon }) => (
						<NavLink
							key={path}
							to={path}
							className={({ isActive }) => {
								return `flex items-center w-full px-2 py-3 border-b ${
									isActive ? "bg-blue-100 text-white" : ""
								}`;
							}}
						>
							<div className="text-lg mr-2">{icon}</div>
							{label}
						</NavLink>
					))}
				</div>
			</div>
			<div className="w-full -ml-64 pl-64 bg-gray-100 overflow-y-auto">
				<div className="p-5">
					<Outlet></Outlet>
				</div>
			</div>
		</div>
	);
};

export default AppLayout;
