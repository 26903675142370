import Animated from "../components/Animated";
import Metro from "../components/Metro";
import LatestArticles from "../components/LatestArticles";

const MetroPage = () => {
	return (
		<div>
			<div className="relative overflow-hidden">
				<div className="container mx-auto">
					<Animated animation="animate-fade">
						<img
							alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
							src="/projects/metro-cover.jpg"
							className="w-full h-auto z-10"
						/>
					</Animated>
				</div>
				<div className="w-full h-auto absolute top-0 left-0 -z-10">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/projects/metro-cover.jpg"
						className="w-full blur-lg"
					/>
				</div>
			</div>
			<div className="container mx-auto bg-white px-2">
				<Metro></Metro>
			</div>
			<div className="bg-gray-100 py-10 pb-20">
				<div className="container mx-auto px-2">
					<LatestArticles category="metro"></LatestArticles>
				</div>
			</div>
		</div>
	);
};

export default MetroPage;
