import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import Card from "../entities/transparency/Card";
import { useParams } from "react-router-dom";
import Animated from "../components/Animated";
import { mainApi } from "../providers/api";
import { ShowWarning } from "../providers/alert";
import { Title } from "../components/Project";

const CATEGORIES = [
	"all",
	"cat1",
	"cat2",
	"cat3",
	"cat4",
	"cat5",
	"cat6",
	"cat7",
	"cat8",
];

const Transparency = () => {
	const { category } = useParams();
	const { t } = useTranslation();
	const [selected, setSelected] = useState(category || "all");

	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

	const fetchItems = async (selected, tag) => {
		try {
			let find = {};

			if (selected !== "all") {
				find["category.value"] = selected;
			}

			if (tag) {
				find["tags.value"] = tag;
			}

			setLoading(true);
			const res = await mainApi({
				method: "POST",
				url: `/public/transparency/list`,
				data: {
					find,
					offset: 0,
					limit: 60,
					sort: { _id: -1 },
				},
			});

			setItems(res.data);
		} catch (err) {
			ShowWarning(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchItems(selected);
	}, [selected]);

	return (
		<div className="bg-gray-100">
			<div className="container mx-auto p-2">
				{loading && <Loader></Loader>}
				<div className="animate-fade text-center my-10 uppercase text-2xl">
					<Title title={t("transparency")} color={"blue"}></Title>
				</div>
				<Animated>
					<div className="w-full flex flex-wrap rounded-lg mb-10 select-none gap-2">
						{CATEGORIES.map((category) => (
							<div
								key={category}
								className={`p-2 text-center uppercase whitespace-nowrap cursor-pointer ${
									selected === category ? `transparency active` : "bg-white"
								}`}
								onClick={() => {
									setSelected(category);
								}}
							>
								{t(`transparency_cat.${category}`)}
							</div>
						))}
					</div>
				</Animated>
				<div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
					{items.map((item, index) => (
						<Card key={index} {...item}></Card>
					))}
				</div>
			</div>
		</div>
	);
};

export default Transparency;
