import { Link } from "react-router-dom";
import { FaEye, FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import Highlighter from "react-highlight-words";

import List from "../../components/List";
import FormControl from "../../inputs/FormControl";
import { useState } from "react";

const UserList = () => {
	const [find, setFind] = useState();

	return (
		<div className="bg-white p-5 shadow-md">
			<List url="user/table" find={find}>
				{(items, { loading }) => (
					<>
						<div className="flex justify-between items-center mb-2 pb-2 border-b">
							<h1>Системд бүртгэлтэй хэрэглэгчид</h1>
							<Link to="new">
								<button className="primary">
									<FaPlus /> Хэрэглэгч бүртгэх
								</button>
							</Link>
						</div>
						<Formik
							initialValues={{ search: "" }}
							onSubmit={({ search }, { setSubmitting }) => {
								setFind(
									search
										? {
												$or: [
													{
														username: { $regex: search, $options: "i" },
													},
													{ name: { $regex: search, $options: "i" } },
													{ position: { $regex: search, $options: "i" } },
													{ phone: { $regex: search, $options: "i" } },
												],
										  }
										: null
								);
								setSubmitting();
							}}
						>
							{({ values, resetForm }) => {
								return (
									<>
										<Form className="grid grid-cols-5 gap-5 mb-10">
											<div className="flex gap-1">
												<Field
													component={FormControl}
													placeholder="Хайлтын утга"
													name="search"
													fetch={loading}
													type="text"
													containerClass="flex-1"
												/>
												<button
													type="button"
													onClick={() => {
														setFind(null);
														resetForm();
													}}
												>
													<FaTimes></FaTimes>
												</button>
											</div>
											<Field
												component={FormControl}
												name={
													<>
														<FaSearch></FaSearch> Хайх
													</>
												}
												fetch={loading}
												type="submit"
												containerClass="w-full self-end primary"
											/>
										</Form>
										<table className="w-full table-auto">
											<thead>
												<tr>
													<td>Нэвтрэх нэр</td>
													<td>Албан тушаал</td>
													<td>Нэр</td>
													<td>Утас</td>
													<td>Бүртгэсэн огноо</td>
													<td>Бүртгэсэн хэрэглэгч</td>
													<td></td>
												</tr>
											</thead>
											<tbody>
												{items.map(
													({
														_id,
														username,
														name,
														phone,
														position,
														_created,
														createdby,
													}) => (
														<tr key={_id} className="border-t">
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={username}
																></Highlighter>
															</td>
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={position}
																></Highlighter>
															</td>
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={name}
																></Highlighter>
															</td>
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={phone}
																></Highlighter>
															</td>
															<td>{_created}</td>
															<td>{createdby?.name}</td>
															<td className="flex justify-end">
																<Link to={`view/${_id}`}>
																	<button>
																		<FaEye></FaEye> Дэлгэрэнгүй
																	</button>
																</Link>
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									</>
								);
							}}
						</Formik>
					</>
				)}
			</List>
		</div>
	);
};

export default UserList;
