import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";

const Map = ({
	center = {
		lat: 47.917837389284216,
		lng: 106.91883066563094,
	},
	zoom = 11,
	onClick,
	onLoaded,
	height,
	disabled,
	className,
}) => {
	const ref = useRef();
	const [map, setMap] = useState();

	useEffect(() => {
		setMap(
			new window.google.maps.Map(ref.current, {
				center,
				zoom,
			})
		); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (map) {
			onLoaded && onLoaded(map);

			if (disabled) {
				window.google.maps.event.clearListeners(map, "click");
			} else {
				map.addListener("click", onClick);
			}
		} // eslint-disable-next-line
	}, [map, disabled]);

	return <div ref={ref} className={`${className}`} style={{ height }} />;
};

const MapSelect = ({
	mapError = "Газрын зураг ачааллахад алдаа гарлаа",
	mapApiKey = "AIzaSyC9y2N8Aa11dI4vVrfhgS2bSpA9qerP--A",
	height = 400,
	value,
	onChange,
	markerOptions,
	lineOptions,
	disabled,
	isMulti,
	noline,
	infos,
	...props
}) => {
	const [map, setMap] = useState();
	const [coords, setCoords] = useState([]);
	const [markers, setMarkers] = useState([]);
	const [line, setLine] = useState();

	const render = (status) => {
		if (status === Status.FAILURE)
			return (
				<div
					style={{ height }}
					className="flex items-center justify-center text-xs bg-gray-100"
				>
					{mapError}
				</div>
			);

		return (
			<div style={{ height }}>
				<Loader></Loader>
			</div>
		);
	};

	const onClick = (e) => {
		const position = {
			lat: e.latLng.lat(),
			lng: e.latLng.lng(),
		};

		setCoords((c) => {
			const tmp = isMulti ? [...c, position] : [position];

			onChange && onChange(isMulti ? tmp : tmp[0]);

			return tmp;
		});
	};

	const onClickMarker = (index) => {
		if (map && !disabled) {
			const tmp = [...coords];

			tmp.splice(index, 1);

			onChange && onChange(isMulti ? tmp : tmp[0]);

			setCoords(tmp);
		}
	};

	const drawMarker = (position, index) => {
		let newMarker;
		if (map && position && position.lat && position.lng) {
			map.panTo(position);
			newMarker = new window.google.maps.Marker({
				styles: [
					{
						featureType: "poi",
						elementType: "labels",
						stylers: [{ visibility: "off" }],
					},
				],
				position,
				map,
				label: infos ? infos[index] : `${index + 1}`,
				...markerOptions,
			});
			newMarker.addListener("click", () => {
				onClickMarker(index);
			});
		}
		return newMarker;
	};

	useEffect(() => {
		if (disabled)
			markers.map((m) => window.google.maps.event.clearListeners(m, "click"));
		else
			markers.map((m, index) =>
				m.addListener("click", () => {
					onClickMarker(index);
				})
			); // eslint-disable-next-line
	}, [disabled]);

	useEffect(() => {
		markers.map((m) => m && m.setMap(null));

		setMarkers(coords.map((coord, index) => drawMarker(coord, index)));

		if (isMulti && coords.length > 0) {
			line && line.setMap(null);
			if (!noline)
				setLine(
					new window.google.maps.Polyline({
						path: coords,
						geodesic: true,
						strokeColor: "#FF0000",
						strokeOpacity: 0.5,
						strokeWeight: 5,
						map,
						...lineOptions,
					})
				);
		} // eslint-disable-next-line
	}, [coords, map]);

	useEffect(() => {
		if (line && map) {
			const bounds = new window.google.maps.LatLngBounds();

			line
				.getPath()
				.getArray()
				.forEach(function (ll) {
					bounds.extend(ll);
				});
			map.fitBounds(bounds);
		}
	}, [line, map]);

	useEffect(() => {
		if (map && value) {
			setCoords(isMulti ? value : [value]);
		}
	}, [value, isMulti, map]);

	const mapProps = {
		height,
		onClick,
		onLoaded: setMap,
		disabled,
		...props,
	};

	return (
		<div style={{ height }}>
			<Wrapper apiKey={mapApiKey} render={render}>
				<Map {...mapProps} />
			</Wrapper>
		</div>
	);
};

export default MapSelect;
