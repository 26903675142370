import { Trans, useTranslation } from "react-i18next";
import Animated from "../components/Animated";
import GreenGate from "../components/GreenGate";
import LatestArticles from "../components/LatestArticles";
import Project, { Title } from "../components/Project";
import { FaMapMarkerAlt } from "react-icons/fa";
import AutoPlayVideo from "../components/AutoPlayVideo";

const Marker = () => {
	return (
		<div className="bg-green-100 p-2 rounded-lg">
			<FaMapMarkerAlt className="text-xl text-white"></FaMapMarkerAlt>
		</div>
	);
};

const GreenGatePage = () => {
	const { t } = useTranslation();

	return (
		<div>
			<div className="relative overflow-hidden">
				<div className="container mx-auto">
					<Animated animation="animate-fade">
						<img
							alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
							src="/projects/greengate-cover.jpg"
							className="w-full h-auto z-10"
						/>
					</Animated>
				</div>
				<div className="w-full h-auto absolute top-0 left-0 -z-10">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/projects/greengate-cover.jpg"
						className="w-full blur-lg"
					/>
				</div>
			</div>
			<div className="container mx-auto bg-white px-2">
				<GreenGate></GreenGate>
			</div>
			<div className="bg-gray-100 py-10">
				<div className="container mx-auto px-2">
					<LatestArticles category="greengate"></LatestArticles>
				</div>
			</div>
			<div className="container mx-auto px-2">
				<div className="py-10">
					<Project
						color="green"
						title={t("greengate.gate1.title")}
						left={
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/gate1.png"
								className="w-full max-h-96 object-cover rounded-lg"
							/>
						}
						right={
							<div className="leading-relaxed greengate">
								<div className="flex items-center gap-2">
									<Marker></Marker>
									<div className="text-lg">{t("greengate.gate1.location")}</div>
								</div>
								<div className="mt-5">{t("greengate.gate1.desc")}</div>
								<ul className="p-5 list-disc">
									<li>
										<Trans t={t}>greengate.gate1.list.0</Trans>
									</li>
									<li>
										<Trans t={t}>greengate.gate1.list.1</Trans>
									</li>
								</ul>
							</div>
						}
					></Project>
				</div>
			</div>
			<div className="bg-gray-100 px-2">
				<div className="container mx-auto">
					<div className="py-10">
						<Project
							color="green"
							title={t("greengate.gate2.title")}
							right={
								<img
									alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
									src="/projects/gate2.png"
									className="w-full max-h-96 object-cover rounded-lg"
								/>
							}
							left={
								<div className="leading-relaxed greengate">
									<div className="flex items-center gap-2">
										<Marker></Marker>

										<div className="text-lg">
											{t("greengate.gate2.location")}
										</div>
									</div>
									<div className="mt-5">{t("greengate.gate2.desc")}</div>
									<ul className="p-5 list-disc">
										<li>
											<Trans t={t}>greengate.gate2.list.0</Trans>
										</li>
										<li>
											<Trans t={t}>greengate.gate2.list.1</Trans>
										</li>
										<li>
											<Trans t={t}>greengate.gate2.list.2</Trans>
										</li>
										<li>
											<Trans t={t}>greengate.gate2.list.3</Trans>
										</li>
									</ul>
								</div>
							}
						></Project>
					</div>
				</div>
			</div>
			<div className="container mx-auto px-2">
				<div className="py-10">
					<Project
						color="green"
						title={t("greengate.gate3.title")}
						left={
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/projects/gate3.png"
								className="w-full max-h-96 object-cover rounded-lg"
							/>
						}
						right={
							<div className="leading-relaxed greengate">
								<div className="flex items-center gap-2">
									<Marker></Marker>
									<div className="text-lg">{t("greengate.gate3.location")}</div>
								</div>
								<div className="mt-5">{t("greengate.gate3.desc")}</div>
								<ul className="p-5 list-disc">
									<li>
										<Trans t={t}>greengate.gate3.list.0</Trans>
									</li>
									<li>
										<Trans t={t}>greengate.gate3.list.1</Trans>
									</li>
									<li>
										<Trans t={t}>greengate.gate3.list.2</Trans>
									</li>
								</ul>
							</div>
						}
					></Project>
				</div>
			</div>
			<div className="bg-gray-100 px-2">
				<div className="container mx-auto">
					<div className="py-10">
						<Project
							color="green"
							title={t("greengate.gate4.title")}
							right={
								<img
									alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
									src="/projects/gate4.png"
									className="w-full max-h-96 object-cover rounded-lg"
								/>
							}
							left={
								<div className="leading-relaxed greengate">
									<div className="flex items-center gap-2">
										<Marker></Marker>
										<div className="text-lg">
											{t("greengate.gate4.location")}
										</div>
									</div>
									<div className="mt-5">{t("greengate.gate4.desc")}</div>
									<ul className="p-5 list-disc">
										<li>
											<Trans t={t}>greengate.gate4.list.0</Trans>
										</li>
										<li>
											<Trans t={t}>greengate.gate4.list.1</Trans>
										</li>
									</ul>
								</div>
							}
						></Project>
					</div>
				</div>
			</div>
			<div className="container mx-auto px-2">
				<div className="py-10 pb-20">
					<Title color="green" title={t("greengate.video")}></Title>
					<Animated>
						<AutoPlayVideo
							src="/video/greengate.mp4"
							className="h-96 w-auto mx-auto rounded-lg"
							controls={true}
						></AutoPlayVideo>
					</Animated>
				</div>
			</div>
		</div>
	);
};

export default GreenGatePage;
