import { Link } from "react-router-dom";
import Project from "./Project";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

const Metro = ({ more }) => {
	const { t } = useTranslation();

	return (
		<Project
			color="red"
			title={t("metro.title")}
			left={
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/projects/metro-min.png"
					className="w-full max-h-96 object-cover"
				/>
			}
			right={
				<div className="leading-relaxed metro">
					<Trans t={t}>metro.desc</Trans>
					<br />
					<br />
					<Trans t={t}>metro.progress</Trans>
					<br />
					<Trans t={t}>metro.src</Trans>
					<br />
					<Trans t={t}>metro.amount</Trans>
					<br />
					<Trans t={t}>metro.ub</Trans>
					<br />
					<Trans t={t}>metro.period</Trans>

					{more && (
						<div className="flex justify-end mt-5">
							<Link to="/metro" className="button red">
								{t("more")}
								<FaChevronRight />
							</Link>
						</div>
					)}
				</div>
			}
		></Project>
	);
};

export default Metro;
