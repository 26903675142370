import { Outlet } from "react-router-dom";
import Animated from "../components/Animated";
import { useTranslation } from "react-i18next";

const AuthLayout = () => {
	const { t } = useTranslation();

	return (
		<div className="h-screen w-full grid grid-cols-1 md:grid-cols-2">
			<div className="middle bg-primary-100">
				<div className="relative overflow-hidden">
					<img
						alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
						src="/ub.jpeg"
						className="h-screen object-cover object-center blur-sm animate-fade"
					/>
					<div className="absolute top-0 left-0 bottom-0 right-0 w-full flex items-center justify-center px-5">
						<Animated animation="animate-fade flex flex-col">
							<img
								alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
								src="/icon.png"
								className="h-32 object-contain"
							/>
							<div className="text-white text-lg text-center mt-10 leading-tight w-72 uppercase">
								{t("ipiu")}
							</div>
						</Animated>
					</div>
				</div>
			</div>
			<div className="middle bg-white">
				<Outlet></Outlet>
			</div>
		</div>
	);
};

export default AuthLayout;
