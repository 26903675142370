import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import sha256 from "crypto-js/sha256";

import { DEBUG, VERSION } from "../defines";
import FormControl from "../inputs/FormControl";
import { mainApi } from "../providers/api";
import { useApp } from "../providers/app";
import { ShowWarning } from "../providers/alert";

const Login = () => {
	const navigate = useNavigate();
	const { login, logout } = useApp();

	const onSubmit = async ({ username, password }, { setSubmitting }) => {
		try {
			const { data } = await mainApi({
				method: "POST",
				url: "/user/login",
				data: {
					username,
					password: sha256(password).toString(),
				},
			});

			setSubmitting();
			login(data);
			navigate("/app/article");
		} catch (error) {
			ShowWarning(error.toString());
		}
	};

	useEffect(() => {
		logout();
	}, [logout]);

	return (
		<Formik
			initialValues={{
				username: DEBUG ? "oogii" : "",
				password: DEBUG ? "test" : "",
			}}
			enableReinitialize={true}
			validationSchema={Yup.object({
				username: Yup.string().required("Нэвтрэх нэр оруулна уу"),
				password: Yup.string().required("Нууц үг оруулна уу"),
			})}
			onSubmit={onSubmit}
		>
			<Form className="animate-fade-up w-1/2 flex flex-col gap-3 my-16">
				<div className="uppercase text-center mb-3">
					Админ удирдлагын
					<br />
					хэсэг
				</div>
				<Field
					component={FormControl}
					label="Нэвтрэх нэр"
					name="username"
					type="text"
				/>
				<Field
					component={FormControl}
					label="Нууц үг"
					name="password"
					type="password"
				/>
				<Field
					component={FormControl}
					name="Нэвтрэх"
					type="submit"
					containerClass="mt-3 primary"
				/>
				<div className="mt-5 text-xs text-center uppercase">v{VERSION}</div>
			</Form>
		</Formik>
	);
};

export default Login;
