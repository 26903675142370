const Loader = () => {
	return (
		<div className="fixed top-0 bottom-0 left-0 right-0 flex h-screen w-full items-center justify-center bg-white bg-opacity-90 z-50 select-none">
			<div className="animate-pulse animate-duration-[2000ms]">
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/icon.png"
					className="animate-spin w-16 animate-duration-[2000ms]"
				/>
			</div>
		</div>
	);
};

export default Loader;
