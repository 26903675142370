const Text = (props) => {
	const { type, onChange, value, rows = 4 } = props;

	if (type === "textarea")
		return (
			<textarea
				{...props}
				rows={rows}
				value={value}
				onChange={(e) => onChange && onChange(e.target.value)}
			></textarea>
		);

	return (
		<input
			{...props}
			value={value}
			onChange={(e) => onChange && onChange(e.target.value)}
			onWheel={(e) => type === "number" && e.target.blur()}
		></input>
	);
};

export default Text;
