import { useContext, createContext, useEffect, useState } from "react";
import { GetFromStorage, RemoveFromStorage, SaveToStorage } from "./storage";
import { mainApi } from "./api";
import { FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

window.addEventListener("storage", (event) => {
	if (event.key === "logged-out") {
		window.location = "/auth/logout";
		window.close();
	}
});

const DRAWER_POSITION = {
	left: "order-0 animate-fade-right",
	right: "order-1 animate-fade-left",
};

const AppContext = createContext({
	user: null,
	login: () => {},
	logout: () => {},
	openDrawer: () => {},
	closeDrawer: () => {},
});

const AppProvider = ({ children }) => {
	const [user, setUser] = useState();
	const [drawer, setDrawer] = useState();

	const location = useLocation();

	const login = async (instance) => {
		setInstance(instance);
	};

	const logout = () => {
		setInstance();
	};

	const setInstance = (instance) => {
		if (instance) {
			SaveToStorage("refreshToken", instance.refreshToken);
			SaveToStorage("accessToken", instance.accessToken);
			setUser(instance.user);
		} else {
			RemoveFromStorage("refreshToken");
			RemoveFromStorage("accessToken");
			setUser(null);
		}
	};

	const openDrawer = (content, position = "right", width = 300) => {
		setDrawer({ content, position, width });
	};

	const closeDrawer = () => {
		setDrawer(null);
	};

	useEffect(() => {
		const refreshUserToken = async (token) => {
			if (token) {
				try {
					const response = await mainApi({
						method: "POST",
						url: "/user/refresh",
						data: {
							token,
						},
					});
					response && setInstance(response.data);
				} catch {}
			}
		};

		refreshUserToken(GetFromStorage("refreshToken"));
	}, []);

	useEffect(() => {
		window.scrollTo({ behavior: "smooth", top: 0 });
	}, [location.pathname]);

	return (
		<AppContext.Provider
			value={{ user, login, logout, openDrawer, closeDrawer }}
		>
			<div className="relative min-h-full text-sm">
				{drawer && (
					<div
						className="fixed top-0 left-0 right-0 z-50 flex h-full"
						onClick={closeDrawer}
					>
						<div
							style={{ width: drawer?.width }}
							className={`relative overflow-y-auto flex-grow-0 bg-white shadow-md z-50 transition-all animate-duration-300 ${
								DRAWER_POSITION[drawer?.position]
							}`}
						>
							<div className="absolute right-2 top-5 w-8 h-8 flex items-center justify-center cursor-pointer hover:opacity-70">
								<FaTimes className="text-lg"></FaTimes>
							</div>
							{drawer?.content}
						</div>
						<div className="flex-1 bg-black bg-opacity-60 z-40 animate-duration-200 animate-fade"></div>
					</div>
				)}
				{children}
			</div>
		</AppContext.Provider>
	);
};

export const useApp = () => useContext(AppContext);
export default AppProvider;
