import { Link, NavLink, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
	FaEnvelope,
	FaFacebook,
	FaGlobe,
	FaInstagram,
	FaLinkedin,
	FaPhone,
	FaYoutube,
} from "react-icons/fa";
import { MdMenu } from "react-icons/md";

import { useApp } from "../providers/app";
import { VERSION } from "../defines";

const MENU = ["home", "metro", "brt", "cablecar", "greengate", "transparency"];
const FOOTER_LINKS = [
	{ label: "metro", link: "/metro" },
	{ label: "cablecar", link: "/cablecar" },
	{ label: "brt", link: "/brt" },
	{ label: "greengate", link: "/greengate" },
	{ label: "articles", link: "/articles" },
	{
		label: "transparency",
		link: "https://shilendans.gov.mn/organization/35870",
		target: "_blank",
	},
];

const PublicLayout = () => {
	const { t, i18n } = useTranslation();
	const { language, changeLanguage } = i18n;
	const { openDrawer } = useApp();

	return (
		<>
			<nav className="h-16 flex sticky top-0 glass animate-fade-down z-40 select-none">
				<div className="container p-2 mx-auto flex items-center justify-between">
					<Link to="/" className="flex flex-shrink-0 items-center gap-2">
						<img
							alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
							src="/logo-full.png"
							className="h-12 mr-5"
						/>
						{/* <div className="w-64 uppercase text-xs leading-tight">
							{t("ipiu")}
						</div> */}
					</Link>
					<div className="flex gap-2 justify-center items-center">
						<div className="hidden lg:flex flex-1 gap-2 items-center uppercase mr-24">
							{MENU.map((item) => (
								<NavLink
									key={item}
									to={item}
									className={({ isActive }) => {
										return `p-2 rounded-lg text-center ${
											isActive ? `${item} active` : "text-dark-70"
										}`;
									}}
								>
									{t(`public-menu.${item}`)}
								</NavLink>
							))}
						</div>
						<div
							className="hidden lg:flex border border-dark-70 rounded-lg text-dark-70 p-2 gap-2 items-center cursor-pointer"
							onClick={() => {
								changeLanguage(language === "mn" ? "en" : "mn");
							}}
						>
							<FaGlobe className="text-xl"></FaGlobe>
							<div className="uppercase">{language}</div>
						</div>
						<div
							className="flex lg:hidden border border-dark-70 p-2 cursor-pointer"
							onClick={() => {
								openDrawer(
									<div className="w-full flex flex-col uppercase">
										<div
											className="flex my-5 text-dark-70 p-2 gap-2 items-center cursor-pointer border border-dark-70 rounded-lg self-start ml-2"
											onClick={() => {
												changeLanguage(language === "mn" ? "en" : "mn");
											}}
										>
											<FaGlobe className="text-xl"></FaGlobe>
											<div className="uppercase">{language}</div>
										</div>
										{MENU.map((item) => (
											<NavLink
												key={item}
												to={item}
												className={({ isActive }) => {
													return `px-2 py-3 ${
														isActive ? `${item} active` : "text-dark-70"
													}`;
												}}
											>
												{t(`public-menu.${item}`)}
											</NavLink>
										))}
									</div>
								);
							}}
						>
							<MdMenu className="text-xl"></MdMenu>
						</div>
					</div>
				</div>
			</nav>
			<Outlet></Outlet>
			<footer className="py-10 relative overflow-hidden">
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/pattern.png"
					className="absolute z-0 top-0 bottom-0 right-0 left-0 w-full h-full object-cover opacity-5"
				/>
				<div className="container mx-auto p-2 text-white z-10 relative">
					<div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
						<div>
							<div className="hidden flex-shrink-0 items-center gap-2 lg:flex">
								<img
									alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
									src="/logo-full-white.png"
									className="h-12"
								/>
								{/* <div className="w-64 uppercase text-xs leading-tight">
									{t("ipiu")}
								</div> */}
							</div>
							<div className="my-10 text-center lg:text-left">
								{t("footer.address")}
							</div>
							<div className="flex flex-wrap gap-2 justify-center lg:justify-start">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.facebook.com/profile.php?id=100093836270301"
									className="text-lg p-2 glass rounded-lg"
								>
									<FaFacebook></FaFacebook>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://instagram.com"
									className="text-lg p-2 glass rounded-lg"
								>
									<FaInstagram></FaInstagram>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://linkedin.com"
									className="text-lg p-2 glass rounded-lg"
								>
									<FaLinkedin></FaLinkedin>
								</a>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://youtube.com"
									className="text-lg p-2 glass rounded-lg"
								>
									<FaYoutube></FaYoutube>
								</a>
							</div>
						</div>
						<div className="text-center lg:text-left my-10 lg:my-0">
							<div className="font-bold mb-5">{t("footer.link")}</div>
							<div className="flex flex-col gap-2">
								{FOOTER_LINKS.map(({ label, link, target }) => (
									<Link key={label} to={link} target={target}>
										{t(`links.${label}`)}
									</Link>
								))}
							</div>
						</div>
						<div className="text-center lg:text-left">
							<div className="font-bold mb-5">{t("footer.contact")}</div>
							<a
								target="_blank"
								rel="noreferrer"
								href="mailto:project@ulaanbaatar.mn"
								className="flex items-center gap-2 mb-2 underline justify-center lg:justify-start"
							>
								<div className="text-lg p-2 glass rounded-lg">
									<FaEnvelope></FaEnvelope>
								</div>
								project@ulaanbaatar.mn
							</a>
							<a
								target="_blank"
								rel="noreferrer"
								href="tel:70001936"
								className="flex items-center gap-2 underline justify-center lg:justify-start"
							>
								<div className="text-lg p-2 glass rounded-lg">
									<FaPhone></FaPhone>
								</div>
								7000-1937
							</a>
						</div>
					</div>
					<div className="text-center mt-20">
						{t("footer.copyright")}
						<br />v{VERSION}
					</div>
				</div>
			</footer>
		</>
	);
};

export default PublicLayout;
