import Animated from "./Animated";

export const Title = ({ title, color }) => {
	return (
		<>
			<Animated animation="animate-fade-down">
				<div className="text-center uppercase text-2xl mt-10 mb-5">{title}</div>
			</Animated>
			<div animation="animate-fade-up">
				<div className={`relative h-1 w-24 mx-auto bg-${color}-100 mt-2 mb-10`}>
					<div className="absolute top-0 left-1/2">
						<div
							className={`bg-${color}-100 rounded-full h-5 w-5 -ml-3`}
							style={{ marginTop: -8 }}
						></div>
						<div
							className={`bg-white rounded-full h-3 w-3 -ml-2`}
							style={{ marginTop: -16 }}
						></div>
					</div>
				</div>
			</div>
		</>
	);
};

const Project = ({ title, color, left, right }) => {
	return (
		<div className="">
			<Title title={title} color={color}></Title>
			<div className="grid grid-cols-1 lg:grid-cols-2 gap-10 text-justify items-center mb-10">
				<Animated animation="flex-1 lg:animate-fade-right animate-fade-up">
					{left}
				</Animated>
				<Animated animation="flex-1 lg:animate-fade-left animate-fade-down">
					{right}
				</Animated>
			</div>
		</div>
	);
};

export default Project;
