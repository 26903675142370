import objectPath from "object-path";
import Loader from "./Loader";

import AutoComplete from "./AutoComplete";
import DateTimePicker from "./DateTimePicker";
import FileUpload, { renderFile } from "./FileUpload";
import MapSelect from "./MapSelect";
import RichText from "./RichText";
import Select from "./Select";
import Switch from "./Switch";
import Text from "./Text";
import { format } from "date-fns";

const SKELETON = {
	textarea: <div className="h-24 bg-slate-200"></div>,
};

const VIEW = {
	text: (v) => v,
	password: (v) => v !== undefined && [...String(v)].map(() => "*"),
	number: (v) => v,
	textarea: (v) => v,
	switch: (v) => (v ? "Тийм" : "Үгүй"),
	file: (v, { axio, height }) =>
		v &&
		v.map((file, index) => (
			<div key={index}>{renderFile(file, axio, height)}</div>
		)),
	select: (v) => v?.label,
	auto: (v) =>
		Array.isArray(v) ? v.map(({ label }) => label).join(", ") : v?.label,
	map: (v) => <MapSelect value={v} disabled={true} />,
	date: (v) => v && format(v, "yyyy-MM-dd"),
	rich: (v) => <div dangerouslySetInnerHTML={{ __html: v }}></div>,
};

const FormControl = ({
	type = "text",
	label,
	info,
	fetch,
	readonly,
	disabled,
	containerClass = "",
	inputClass = "w-full",
	placeholder,
	view,
	field,
	form,
	children,
	...props
}) => {
	const name = field?.name || props.name;
	const error = objectPath.get(form?.errors, name) || props.error;
	const value = field?.value || props.value;

	if (fetch)
		return (
			<div className={`field ${containerClass}`}>
				<div className="animate-pulse">
					{label && <div className="h-4 mb-1 bg-slate-200"></div>}
					{info && <div className="h-2 mb-1 bg-slate-200"></div>}
					{SKELETON[type] || <div className="h-10 bg-slate-200"></div>}
				</div>
			</div>
		);

	if (readonly)
		return (
			<div className={`field ${containerClass}`}>
				{label && <div className="label">{label}</div>}
				<div className="view whitespace-pre-line flex gap-2">
					{view ? view(value, props) : VIEW[type] && VIEW[type](value, props)}
				</div>
			</div>
		);

	const inputProps = {
		...props,
		...field,
		type,
		className: `input ${inputClass}`,
		placeholder: placeholder === false ? "" : placeholder || label,
		disabled,
		onChange: (val) => {
			form?.setFieldValue(name, val);
		},
		onError: (msg) => {
			form?.setFieldError(name, msg);
		},
		submit: form?.isSubmitting?.toString(),
	};

	return (
		<div className={`field ${containerClass}`}>
			<div className={`label ${error ? "error" : ""}`}>{`${label || ""} ${
				error ? `(${error})` : ""
			}`}</div>
			{info && <div className="info">{info}</div>}
			<div className="relative">
				{form?.isSubmitting && <Loader></Loader>}
				{["button", "submit"].includes(type) ? (
					<button {...inputProps}>{children || name}</button>
				) : type === "auto" ? (
					<AutoComplete {...inputProps}></AutoComplete>
				) : type === "date" ? (
					<DateTimePicker
						{...inputProps}
						wrapperClassName="w-full"
					></DateTimePicker>
				) : type === "file" ? (
					<FileUpload {...inputProps}></FileUpload>
				) : type === "map" ? (
					<MapSelect {...inputProps}></MapSelect>
				) : type === "rich" ? (
					<RichText {...inputProps}></RichText>
				) : type === "select" ? (
					<Select {...inputProps}></Select>
				) : type === "switch" ? (
					<Switch {...inputProps}></Switch>
				) : (
					<Text {...inputProps}></Text>
				)}
			</div>
		</div>
	);
};

export default FormControl;
