import { useApp } from "../providers/app";
import UserForm from "../entities/user/Form";

const Profile = () => {
	const { user } = useApp();

	if (user?.id) return <UserForm profile_id={user.id}></UserForm>;
};

export default Profile;
