import { useEffect, useState } from "react";

const Select = (props) => {
	const {
		disabled,
		onChange,
		isMulti,
		options = [],
		value,
		valueField = "value",
		labelField = "label",
		itemClass = "select-none",
		activeItemClass = "bg-gray-200",
		mode,
	} = props;

	const [selected, setSelected] = useState({});

	const onClickItem = (option) => {
		const s = {
			...(isMulti ? selected : null),
			[option[valueField]]: selected[option[valueField]] ? undefined : option,
		};

		onChange &&
			onChange(
				isMulti ? Object.values(s).filter((m) => m) : Object.values(s)[0]
			);
	};

	useEffect(() => {
		if (isMulti) {
			if (Array.isArray(value)) {
				const s = value.reduce((prev, cur) => {
					prev[cur[valueField]] = cur;
					return prev;
				}, {});
				setSelected(s);
			}
		} else {
			if (value) {
				setSelected({ [value[valueField]]: value });
			} else {
				setSelected({});
			}
		}
	}, [value, valueField, isMulti]);

	return (
		<div className={`selectable-container`}>
			{options.map((option, index) => {
				const active = !!selected[option[valueField]];
				const label = option[labelField];

				return (
					<div
						key={index}
						className={`selectable-item cursor-pointer ${itemClass} ${
							active ? activeItemClass : ""
						}`}
						onClick={() => {
							!disabled && onClickItem(option);
						}}
					>
						{["checkbox", "radio"].includes(mode) ? (
							<div className="flex cursor-pointer">
								<input
									type={mode}
									checked={active}
									disabled={disabled}
									className="cursor-pointer mr-1"
									readOnly
								></input>
								<div>{label}</div>
							</div>
						) : (
							label
						)}
					</div>
				);
			})}
		</div>
	);
};

export default Select;
