import { Link } from "react-router-dom";
import Project from "./Project";
import { Trans, useTranslation } from "react-i18next";
import { FaChevronRight } from "react-icons/fa";

const CableCar = ({ more }) => {
	const { t } = useTranslation();

	return (
		<Project
			color="blue"
			title={t("cablecar.title")}
			left={
				<img
					alt="УЛААНБААТАР ХОТЫН АВТО ЗАМЫН ТҮГЖРЭЛИЙГ БУУРУУЛАХ НЭГДСЭН ТӨСӨЛ ХЭРЭГЖҮҮЛЭХ НЭГЖ"
					src="/projects/cablecar-min.png"
					className="w-full max-h-96 object-cover"
				/>
			}
			right={
				<div className="leading-relaxed cablecar">
					<Trans t={t}>cablecar.desc</Trans>
					<br />
					<br />
					<Trans t={t}>cablecar.progress</Trans>
					<br />
					<Trans t={t}>cablecar.src</Trans>
					<br />
					<Trans t={t}>cablecar.amount</Trans>
					<br />
					<Trans t={t}>cablecar.ub</Trans>
					<br />
					<Trans t={t}>cablecar.period</Trans>
					{more && (
						<div className="flex justify-end mt-5">
							<Link to="/cablecar" className="button blue">
								{t("more")}
								<FaChevronRight />
							</Link>
						</div>
					)}
				</div>
			}
		></Project>
	);
};

export default CableCar;
