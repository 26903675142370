import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "./Loader";
import { Link, useParams } from "react-router-dom";
import Animated from "../components/Animated";
import { ShowWarning } from "../providers/alert";
import { mainApi } from "../providers/api";
import objectPath from "object-path";
import { FaClock, FaEye, FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import LatestArticles from "../components/LatestArticles";
import { API_ROOT, DOMAIN } from "../defines";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Article = () => {
	const { id } = useParams();
	const { t, i18n } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [article, setArticle] = useState();

	const { language } = i18n;

	const category = objectPath.get(article, "category.value");
	const category_label = objectPath.get(article, "category.label");

	const title = objectPath.get(article, `title.${language}`);
	const content = objectPath.get(article, `content.${language}`);

	const thumb = objectPath.get(article, `thumb`);

	const url = `https://${DOMAIN}/${category}/articles/${id}`;

	const fetchItem = async (id) => {
		try {
			setLoading(true);
			const res = await mainApi(`/public/article/${id}`);
			setArticle(res.data);
		} catch (err) {
			ShowWarning(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (id) {
			fetchItem(id);
		}
	}, [id]);

	return (
		<div className="container mx-auto py-10 px-2">
			{loading && <Loader></Loader>}
			<div className="grid lg:flex gap-5">
				<div className="flex-1">
					<div className="flex">
						<div className={`p-2 rounded-lg active ${category}`}>
							{category_label}
						</div>
					</div>
					<div className="my-5 uppercase text-2xl">{title}</div>
					<div className="my-5 flex justify-between gap-5 text-dark-70">
						<div className="flex gap-5">
							<div className="flex gap-2 items-center">
								<FaClock className="text-blue-100"></FaClock>{" "}
								{article?._published}
							</div>
							<div className="flex gap-2 items-center">
								<FaEye className="text-blue-100"></FaEye> {article?.views}{" "}
								{t("views")}
							</div>
						</div>
						<div className="flex gap-3">
							<FacebookShareButton url={url}>
								<div className="bg-blue-100 text-white p-2 rounded-lg">
									<FaFacebookF className="text-xl"></FaFacebookF>
								</div>
							</FacebookShareButton>
							<TwitterShareButton url={url}>
								<div className="bg-dark-100 text-white p-2 rounded-lg">
									<FaXTwitter className="text-xl"></FaXTwitter>
								</div>
							</TwitterShareButton>
						</div>
					</div>
					<Carousel interval={5000} dynamicHeight={true}>
						{Array.isArray(thumb) &&
							thumb.map(({ id }) => (
								<img
									key={id}
									src={API_ROOT + "/" + id}
									alt={title}
									crossOrigin="annonymous"
									className="w-full object-cover animate-fade rounded-lg"
								/>
							))}
					</Carousel>
					<Animated>
						<div
							dangerouslySetInnerHTML={{ __html: content }}
							className={`whitespace-pre-line text-justify ${category} mt-5`}
						></div>
					</Animated>
					<Animated animation="animate-fade-down">
						<div className="flex flex-wrap gap-3">
							<FacebookShareButton url={url}>
								<div className="bg-blue-100 text-white p-2 rounded-lg flex px-5 gap-2">
									<FaFacebookF className="text-xl"></FaFacebookF>
									{t("facebook")}
								</div>
							</FacebookShareButton>
							<TwitterShareButton url={url}>
								<div className="bg-dark-100 text-white p-2 rounded-lg flex px-5 gap-2">
									<FaXTwitter className="text-xl"></FaXTwitter>
									{t("tweet")}
								</div>
							</TwitterShareButton>
						</div>
					</Animated>
				</div>
				<div className="w-full lg:w-1/3 flex flex-col gap-5">
					<Animated animation="animate-fade">
						<Link
							to="/metro"
							className="relative block rounded-lg overflow-hidden"
						>
							<img
								alt={title}
								className="h-64 w-full object-cover"
								src="/projects/metro-min.png"
							/>
							<div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-60 flex items-center justify-center text-white text-2xl hover:bg-opacity-25">
								UB METRO
							</div>
						</Link>
					</Animated>
					<Animated animation="animate-fade">
						<Link
							to="/brt"
							className="relative block rounded-lg overflow-hidden"
						>
							<img
								alt={title}
								className="h-64 w-full object-cover"
								src="/projects/brt-min.png"
							/>
							<div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-60 flex items-center justify-center text-white text-2xl hover:bg-opacity-25">
								UB BRT
							</div>
						</Link>
					</Animated>
					<Animated animation="animate-fade">
						<Link
							to="/cablecar"
							className="relative block rounded-lg overflow-hidden"
						>
							<img
								alt={title}
								className="h-64 w-full object-cover"
								src="/projects/cablecar-min.png"
							/>
							<div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-60 flex items-center justify-center text-white text-2xl hover:bg-opacity-25">
								UB CABLECAR
							</div>
						</Link>
					</Animated>
					<Animated animation="animate-fade">
						<Link
							to="/greengate"
							className="relative block rounded-lg overflow-hidden"
						>
							<img
								alt={title}
								className="h-64 w-full object-cover"
								src="/projects/greengate-min.png"
							/>
							<div className="absolute top-0 left-0 right-0 bottom-0 z-10 bg-black bg-opacity-60 flex items-center justify-center text-white text-2xl hover:bg-opacity-25">
								UB GREENGATE
							</div>
						</Link>
					</Animated>
				</div>
			</div>
			<div>
				<LatestArticles category={category} id={id}></LatestArticles>
			</div>
		</div>
	);
};

export default Article;
