import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { mainApi } from "../providers/api";
import { ShowWarning } from "../providers/alert";
import Loader from "../inputs/Loader";

const LIMITS = [20, 50, 100];
const paginationItemclass =
	"h-8 w-8 flex items-center justify-center cursor border text-xs rounded-lg";
const List = ({ url, find, sort, children }) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(20);

	const [loading, setLoading] = useState(true);

	const [total, setTotal] = useState(0);
	const [items, setItems] = useState([]);

	useEffect(() => {
		const fetchList = async () => {
			try {
				setLoading(true);
				const { data } = await mainApi({
					method: "POST",
					url,
					data: { find, sort, offset, limit },
				});

				setItems(data.items);
				setTotal(data.total);
			} catch (error) {
				ShowWarning(error);
			} finally {
				setLoading(false);
			}
		};

		fetchList();
	}, [url, find, sort, offset, limit]);

	useEffect(() => {
		setOffset(0);
	}, [limit]);

	return (
		<div className="w-full relative">
			{loading && <Loader></Loader>}
			{children && children(items, { total, loading })}
			<div className="flex flex-wrap gap-5 items-center justify-center text-xs select-none mt-5">
				<div className="flex items-center">
					Харуулах хэмжээ:
					<select
						className="text-center h-10 ml-1 cursor"
						value={limit}
						onChange={(e) => setLimit(+e.target.value)}
					>
						{LIMITS.map((item) => (
							<option key={item} value={item}>
								{item}
							</option>
						))}
					</select>
				</div>
				<ReactPaginate
					onPageChange={(e) => setOffset(e.selected)}
					pageCount={Math.ceil(total / limit)}
					breakLabel="..."
					nextLabel=">"
					previousLabel="<"
					containerClassName="flex flex-wrap justify-center items-center gap-2"
					activeLinkClassName="bg-blue-100 text-white p-2"
					nextLinkClassName={paginationItemclass}
					previousLinkClassName={paginationItemclass}
					pageLinkClassName={paginationItemclass}
					breakLinkClassName={paginationItemclass}
				/>
			</div>
		</div>
	);
};

export default List;
