const Loader = () => {
	return (
		<div className="bg-slate-200 absolute top-0 left-0 right-0 bottom-0 bg-opacity-50 flex items-center justify-center z-10 cursor-wait">
			<svg
				className="h-5"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 60 40"
				enableBackground="new 0 0 0 0"
			>
				<circle fill="#fff" stroke="none" cx="6" cy="20" r="6">
					<animateTransform
						attributeName="transform"
						dur="1s"
						type="translate"
						values="0 15 ; 0 -15; 0 15"
						repeatCount="indefinite"
						begin="0.1"
					/>
				</circle>
				<circle fill="#fff" stroke="none" cx="30" cy="20" r="6">
					<animateTransform
						attributeName="transform"
						dur="1s"
						type="translate"
						values="0 10 ; 0 -10; 0 10"
						repeatCount="indefinite"
						begin="0.2"
					/>
				</circle>
				<circle fill="#fff" stroke="none" cx="54" cy="20" r="6">
					<animateTransform
						attributeName="transform"
						dur="1s"
						type="translate"
						values="0 5 ; 0 -5; 0 5"
						repeatCount="indefinite"
						begin="0.3"
					/>
				</circle>
			</svg>
		</div>
	);
};

export default Loader;
