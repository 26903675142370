import { Link } from "react-router-dom";
import { FaEye, FaPlus, FaSearch, FaTimes } from "react-icons/fa";
import { Field, Form, Formik } from "formik";
import Highlighter from "react-highlight-words";

import List from "../../components/List";
import FormControl from "../../inputs/FormControl";
import { useState } from "react";
import { renderFile } from "../../inputs/FileUpload";
import { mainApi } from "../../providers/api";
import objectPath from "object-path";

const ArticleList = () => {
	const [find, setFind] = useState();

	return (
		<div className="p-5 bg-white shadow-md">
			<List url="article/table" find={find}>
				{(items, { loading }) => (
					<>
						<div className="flex justify-between items-center mb-2 pb-2 border-b">
							<h1>Мэдээд мэдээлэл</h1>
							<Link to="new">
								<button className="primary">
									<FaPlus /> Шинээр бүртгэх
								</button>
							</Link>
						</div>
						<Formik
							initialValues={{ search: "" }}
							onSubmit={({ search }, { setSubmitting }) => {
								setFind(
									search
										? {
												$or: [
													{ "title.mn": { $regex: search, $options: "i" } },
													{ "title.en": { $regex: search, $options: "i" } },
												],
										  }
										: null
								);
								setSubmitting();
							}}
						>
							{({ values, resetForm }) => {
								return (
									<>
										<Form className="grid grid-cols-5 gap-5 mb-10">
											<div className="flex gap-1">
												<Field
													component={FormControl}
													placeholder="Хайлтын утга"
													name="search"
													fetch={loading}
													type="text"
													containerClass="flex-1"
												/>
												<button
													type="button"
													onClick={() => {
														setFind(null);
														resetForm();
													}}
												>
													<FaTimes></FaTimes>
												</button>
											</div>
											<Field
												component={FormControl}
												name={
													<>
														<FaSearch></FaSearch> Хайх
													</>
												}
												fetch={loading}
												type="submit"
												containerClass="w-full self-end primary"
											/>
										</Form>
										<table className="w-full table-auto overflow-x-auto">
											<thead>
												<tr>
													<td>Зураг</td>
													<td>Ангилал</td>
													<td>Мэдээний TAG</td>
													<td>Гарчиг</td>
													<td>Гарчиг/EN/</td>
													<td>Хандалтын тоо</td>
													<td>Бүртгэсэн огноо</td>
													<td>Бүртгэсэн хэрэглэгч</td>
													<td></td>
												</tr>
											</thead>
											<tbody>
												{items.map(
													({
														_id,
														category,
														tags,
														thumb,
														title,
														views,
														_created,
														createdby,
													}) => (
														<tr key={_id} className="border-t">
															<td className="w-32">
																{renderFile(
																	objectPath.get(thumb, 0),
																	mainApi,
																	100
																)}
															</td>
															<td>{category?.label}</td>
															<td>
																{Array.isArray(tags) &&
																	tags.map(({ label }) => label).join(", ")}
															</td>
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={title.mn}
																></Highlighter>
															</td>
															<td>
																<Highlighter
																	searchWords={[values.search]}
																	textToHighlight={title.en}
																></Highlighter>
															</td>
															<td>{views}</td>
															<td>{_created}</td>
															<td>{createdby?.name}</td>
															<td>
																<Link to={`view/${_id}`}>
																	<button>
																		<FaEye></FaEye> Дэлгэрэнгүй
																	</button>
																</Link>
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									</>
								);
							}}
						</Formik>
					</>
				)}
			</List>
		</div>
	);
};

export default ArticleList;
