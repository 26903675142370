import ReactSelect from "react-select";

const AutoComplete = ({
	disabled: isDisabled,
	noOptionsMessage = ({ inputValue }) =>
		`"${inputValue}" тохирох илэрц олдсонгүй!`,
	...props
}) => {
	const selectProps = {
		noOptionsMessage,
		isDisabled,
		...props,
		className: "p-0",
		classNamePrefix: "autocomplete",
	};

	return <ReactSelect {...selectProps}></ReactSelect>;
};

export default AutoComplete;
