import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Animated from "./Animated";
import { useEffect, useState } from "react";
import Loader from "../pages/Loader";
import { mainApi } from "../providers/api";
import ArticleCard from "../entities/article/Card";
import { ShowWarning } from "../providers/alert";

const COLOR = {
	metro: "border-red-100",
	brt: "border-yellow-100",
	cablecar: "border-blue-100",
	greengate: "border-green-100",
};

const LatestArticles = ({ category, id }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);

	const fetchItems = async (id, category) => {
		try {
			setLoading(true);
			const res = await mainApi({
				method: "POST",
				url: `/public/article/list`,
				data: {
					find: id
						? {
								$and: [{ "category.value": category }, { _id: { $ne: id } }],
						  }
						: { "category.value": category },
					offset: 0,
					limit: 3,
					sort: { _id: -1 },
				},
			});

			setItems(res.data);
		} catch (err) {
			ShowWarning(err.toString());
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchItems(id, category);
	}, [id, category]);

	return (
		<div>
			{loading && <Loader></Loader>}
			<div className="flex flex-wrap justify-between items-center my-10">
				<Animated animation="animate-fade-right">
					<div
						className={`border-l-4 text-2xl uppercase font-bold pl-2 ${COLOR[category]}`}
					>
						{t("relative")}
					</div>
				</Animated>
				<Animated animation="animate-fade-left">
					<Link
						className="p-2 px-5 rounded-lg bg-dark-100 text-white cursor-pointer uppercase"
						to={`/${category}/articles`}
					>
						{t("all")}
					</Link>
				</Animated>
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-3 gap-5">
				{items.map((item, index) => (
					<ArticleCard key={index} {...item} />
				))}
			</div>
		</div>
	);
};

export default LatestArticles;
