import packageJson from "../package.json";

export const DEBUG = process.env.NODE_ENV === "development";
export const API_ROOT = DEBUG ? "http://localhost:8001" : "https://api.ipiu.mn";
export const VERSION = packageJson.version;
export const DOMAIN = packageJson.config.domain;

export const YEAR = () => {
	return new Date().getFullYear();
};

export const waitFor = (ms = 3000) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};
